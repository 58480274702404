<template>
  <div id="app">
    <UserAuth />
  </div>
</template>

<script>
import UserAuth from './UserAuth.vue';

export default {
  name: 'App',
  components: {
    UserAuth,
  },
};
</script>

<style>
/* Add any global styles here */
</style>