<template>
  <div>
    <h1>User Authentication</h1>

    <div v-if="step === 1">
      <h2>Register</h2>
      <input v-model="registerData.username" placeholder="Username" />
      <input v-model="registerData.password" type="password" placeholder="Password" />
      <input v-model="registerData.email" placeholder="Email" />
      <input v-model="registerData.phone" placeholder="Phone" />
      <button @click="register">Register</button>
    </div>

    <div v-if="step === 2">
      <h2>Verify Email</h2>
      <input v-model="verificationCode" placeholder="Enter Verification Code" />
      <button @click="verifyEmail">Verify</button>
      <div v-if="countdown > 0">
        <p>Resend code in {{ countdown }} seconds</p>
      </div>
      <button v-if="countdown === 0" @click="resendCode">Resend Code</button>
    </div>

    <div v-if="step === 3">
      <h2>Login</h2>
      <input v-model="loginData.username" placeholder="Username" />
      <input v-model="loginData.password" type="password" placeholder="Password" />
      <button @click="login">Login</button>
    </div>

    <div v-if="token">
      <h2>Welcome!</h2>
      <p>Your token: {{ token }}</p>
    </div>

    <div v-if="errorMessage" style="color: red;">
      <p>{{ errorMessage }}</p>
    </div>
    <h3>registerData Data:</h3>
    <pre>{{ registerData }}</pre> <!-- 显示 loginData 的内容 -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      step: 1,
      registerData: {
        username: '',
        password: '',
        email: '',
        phone: '',
      },
      verificationCode: '',
      loginData: {
        username: '',
        password: '',
      },
      token: '',
      errorMessage: '',
      countdown: 0,
      countdownInterval: null,
    };
  },
  methods: {
    async register() {
      try {
        const response = await axios.post('http://www.drivenyc.shop:12390/register', this.registerData);
        alert(response.data.message);
        this.step = 2; // Move to verification step
        this.startCountdown(); // Start countdown for resend
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.error || 'Registration failed';
        } else {
          this.errorMessage = 'Registration failed: ' + error.message;
        }
      }
    },
    async verifyEmail() {
      try {
        const response = await axios.post('http://www.drivenyc.shop:12390/verify', {
          email: this.registerData.email,
          password: this.verificationCode, // Using verification code as password for verification
        });
        alert(response.data.message);
        this.step = 3; // Move to login step
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.error || 'Verification failed';
        } else {
          this.errorMessage = 'Verification failed: ' + error.message;
        }
      }
    },
    async login() {
      try {
        const response = await axios.post('http://www.drivenyc.shop:12390/login', this.loginData);
        this.token = response.data.token;
        this.errorMessage = '';
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.error || 'Login failed';
        } else {
          this.errorMessage = 'Login failed: ' + error.message;
        }
      }
    },
    startCountdown() {
      this.countdown = 60; // Set countdown to 60 seconds
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },
    async resendCode() {
      try {
        const response = await axios.post('http://www.drivenyc.shop:12390/register', this.registerData);
        alert(response.data.message);
        this.startCountdown(); // Restart countdown after resending
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.error || 'Could not resend code';
        } else {
          this.errorMessage = 'Could not resend code: ' + error.message;
        }
      }
    },
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  },
};
</script>

<style scoped>
input {
  display: block;
  margin: 10px 0;
}
</style>
